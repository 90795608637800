import { Form } from "@marketpartner/backend-api"
import { MuiFormElements } from "@marketpartner/mp-common-mui"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { Box, DialogContent } from "@mui/material"
import { FC, FormEventHandler, useState } from "react"
import { backend, legacyBackend } from "src/common/backend"
import { useEvent } from "src/common/event-context"
import { LoadingSpinner } from "src/common/LoadingSpinner"
import { useFormContext } from "src/forms/form-context"
import { useInteractionOverlay } from "src/interaction/interaction-overlay-context"
import { SubmitButton } from "src/interaction/SubmitButton"
import { useScannerSettings } from "src/settings/scanner-settings-context"

export const FormUi: FC = () => {
    const event = useEvent()
    const { formId } = useScannerSettings()
    const formQuery = backend.forms.useGet([event.clientId, event.id, formId!], {
        enabled: Boolean(formId)
    })

    if (!formQuery.data) {
        return <LoadingSpinner />
    }

    return <LoadedFormUi form={formQuery.data as Form} />
}

type LoadedFormUiProps = {
    form: Form,
}

const LoadedFormUi: FC<LoadedFormUiProps> = ({
    form,
}) => {
    const { selection, close } = useInteractionOverlay()
    const { submit } = useFormContext()
    const [validationEnabled, setValidationEnabled] = useState(false)
    const state = useDynamicFormState({
        elements: form.elementList,
        requirement: "complete",
    })

    if (!selection) {
        return <></>
    }

    const onSubmit: FormEventHandler = e => {
        e.preventDefault()
        setValidationEnabled(true)
        if (state.invalidFields.length === 0) {
            submit(selection.token, state.values)
            close()
        }
    }

    const formElementId = `dynamic-form-${form.id}`
    return <>
        <DialogContent>
            <Box
                id={formElementId}
                component="form"
                noValidate
                onSubmit={onSubmit}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    my: 3,
                    gap: 4,
                }}
            >
                <Box component="fieldset" sx={{
                    maxWidth: theme => theme.breakpoints.values.md,
                    width: "100%",
                    px: 0,
                }}>
                    <MuiFormElements
                        backend={legacyBackend}
                        state={state}
                        validationEnabled={validationEnabled}
                        allowBase64Uploads
                    />
                </Box>
                <SubmitButton type="submit" form={formElementId}>
                    Submit
                </SubmitButton>
            </Box>
        </DialogContent>
    </>
}