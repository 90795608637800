import { MuiFormElements } from "@marketpartner/mp-common-mui"
import { useDynamicFormState } from "@marketpartner/mp-common-react"
import { Close } from "@mui/icons-material"
import { Box, Button, DialogActions, DialogContent, DialogProps, DialogTitle, Stack } from "@mui/material"
import { FC, useState } from "react"
import { backend, legacyBackend } from "src/common/backend"
import { useEvent } from "src/common/event-context"
import { EventAdminLink } from "src/common/EventAdminLink"
import { LoadingSpinner } from "src/common/LoadingSpinner"
import { ResponsiveDialog } from "src/common/ResponsiveDialog"
import { ResponsiveIconButton } from "src/common/ResponsiveIconButton"


export type FormPreviewDialogProps = Omit<DialogProps, "onClose"> & {
    formId: number
    onClose: () => unknown
}

export const FormPreviewDialog: FC<FormPreviewDialogProps> = ({
    formId,
    onClose,
    ...props
}) => {
    const event = useEvent()
    const [validationEnabled, setValidationEnabled] = useState(false)
    const formQuery = backend.forms.useGet([event.clientId, event.id, formId!], {
        enabled: Boolean(formId),
        refetchInterval: 5_000,
    })
    const state = useDynamicFormState({
        elements: formQuery.data?.elementList ?? [],
        requirement: "complete",
    })

    const reset = () => {
        state.reset()
        setValidationEnabled(false)
    }

    return <ResponsiveDialog
        onClose={onClose}
        {...props}
        maxWidth="md"
        fullWidth
    >
        <Stack flexDirection="row" sx={{
            alignItems: "center",
        }}>
            <DialogTitle>
                {formQuery.data?.name ?? "Form"} (preview)
            </DialogTitle>
            <Box sx={{ flex: "1" }} />
            <ResponsiveIconButton onClick={() => onClose()} sx={{ m: 1 }}>
                <Close />
            </ResponsiveIconButton>
        </Stack>
        <DialogContent>
            {formQuery.data ?
                <MuiFormElements
                    backend={legacyBackend}
                    state={state}
                    validationEnabled={validationEnabled}
                    allowBase64Uploads
                /> :
                <LoadingSpinner />
            }
        </DialogContent>
        <DialogActions>
            <EventAdminLink subPath={`forms/${formId}`}>
                Edit in admin console
            </EventAdminLink>
            <Box sx={{ mx: "auto" }} />
            <Button color="inherit" onClick={reset}>
                Reset
            </Button>
            <Button color="primary" onClick={() => setValidationEnabled(true)}>
                Validate
            </Button>
        </DialogActions>
    </ResponsiveDialog>
}